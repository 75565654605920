@use '@angular/material' as mat;

@mixin sole-text() {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	margin-top: 5rem;

	@include media('>=phone') {
		margin-top: 10rem;
	}
}

@mixin fancy-oval() {
	content: '';
	background-image: url('/assets/fancy-oval.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: absolute;
	display: block;
	width: 643px;
	height: 588px;
	z-index: -1;
}

@mixin bitmap-bg($url) {
	content: '';
	background-image: url($url);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	display: block;
}

@mixin reset-absolute-positioning($value: unset) {
	top: $value;
	left: $value;
	right: $value;
	bottom: $value;
}

@mixin promoLink($pseudoBottomBorderPosition: 'before') {
	@include link($pseudoBottomBorderPosition);

	@if ($pseudoBottomBorderPosition == 'before' or $pseudoBottomBorderPosition == 'after') {
		&:#{$pseudoBottomBorderPosition} {
			background: currentColor;
		}

		&:hover:#{$pseudoBottomBorderPosition} {
			opacity: 0.7;
		}
	}
}

@mixin popup-elevation() {
	@include elevation(14, $color-blue-zodiac, 0.5);
}

// Enlarges container by ignoring root container paddings.
@mixin negateRootContainerHorizontalPaddings() {
	margin-left: -$root-container-padding-x-sm;
	margin-right: -$root-container-padding-x-sm;

	@include media('>=tablet') {
		margin-left: -$root-container-padding-x-md;
		margin-right: -$root-container-padding-x-md;
	}

	@include media('>=laptop') {
		margin-left: -$root-container-padding-x;
		margin-right: -$root-container-padding-x;
	}
}

// Reapplies base container paddings, see negateRootContainerHorizontalPaddings mixin.
@mixin applyRootContainerHorizontalPaddings() {
	margin-left: $root-container-padding-x-sm;
	margin-right: $root-container-padding-x-sm;

	@include media('>=tablet') {
		margin-left: $root-container-padding-x-md;
		margin-right: $root-container-padding-x-md;
	}

	@include media('>=laptop') {
		margin-left: $root-container-padding-x;
		margin-right: $root-container-padding-x;
	}
}

/**
 * Enlarges container itself to full screen width keeping its position.
 */
@mixin fullWidth($min-margin: 0px) {
	@include negateRootContainerHorizontalPaddings();

	@include media('>=laptop') {
		$container-with-padding: Min(100vw, #{$root-container-max-width + $root-container-padding-x * 2});
		$margin: calc(-1 * (#{$root-container-padding-x} + (100vw - #{$container-with-padding}) / 2) + #{$min-margin});

		width: calc(100vw - #{$min-margin * 2});
		margin-left: $margin;
		margin-right: $margin;
	}
}

@mixin slideXRtlKeyframes($name, $sourceWidth) {
	@keyframes #{$name} {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-1 * #{$sourceWidth}));
		}
	}
}

@mixin arrow-right() {
	content: '';
	background-color: currentColor;
	mask-image: url(/assets/arrow-right.svg);
	mask-size: contain;
	mask-repeat: no-repeat;
	width: 0.75em;
	height: 0.625em;
	display: inline-block;
}

@mixin hide-scrollbar() {
	// For FF
	-ms-overflow-style: none;
	scrollbar-width: none;

	// For Chrome
	&::-webkit-scrollbar {
		display: none;
		scrollbar-width: none;
		scrollbar-gutter: none;
	}
}

@mixin footer-trust-logos($scale: 1) {
	.verified-by-visa {
		width: calc($scale * 58px);
	}

	.master-card {
		width: calc($scale * 64px);
	}

	.visa-secure,
	.visa-secure-white {
		width: calc($scale * 45px);
	}

	.visa-on-the-list {
		width: calc($scale * 69px);
	}

	.pci-dss {
		width: calc($scale * 76px);
	}

	.ssl-secure {
		width: calc($scale * 56px);
	}
}

@mixin line-clamp($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin hideScrollbar() {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin dotted-bg($linearGradient...) {
	content: '';
	display: block;
	z-index: -1;
	@include absolute-spread();
	@include fullWidth();

	$imageUrl: url('/assets/shared/dotted-bg.png');

	@if (length($linearGradient) == 0) {
		background: linear-gradient(
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0) 30%,
				rgba(255, 255, 255, 0) 60%,
				rgba(255, 255, 255, 1) 100%
			),
			$imageUrl;
	} @else {
		background: $linearGradient, $imageUrl;
	}
}
