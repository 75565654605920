.career-locations {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 5px;

	&:before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 22px;
		background: url('/assets/geopoint.svg') center / contain no-repeat;
		margin-right: 8px;
		vertical-align: middle;
	}
}

bp-spinning-logo {
	display: block;
	width: 160px;
	margin-left: auto;
	margin-right: auto;
}
