@use '@angular/material' as mat;

$flag-icons-path: '/flags';
@import 'flag-icons/sass/flag-icons.scss'; // country flags

$padding-x: 1.375rem;
$round-control-height: 52px;
$round-control-border-width: 1px;

input {
	@include placeholder-color($color-slate-gray);
}

mat-form-field.mat-form-field-appearance-outline {
	.mat-form-field-prefix {
		margin-left: -1em;
		margin-right: 0.9em;
	}
}

mat-form-field.mat-form-field-appearance-standard {
	.mat-form-field-prefix {
		margin-right: 0.9em;
	}

	.mat-form-field-flex {
		align-items: baseline !important;
	}
}

.control-appearance-round {
	@include mat.typography-level($core-typography-config, button);
	box-shadow: $control-shadow;
	border: $round-control-border-width solid $color-mystic;
	border-radius: 1.7333em;
	padding: 0 $padding-x;
	transition: $animation-duration $animation-function;
	transition-property: background, border-color;
	position: relative;
	overflow: hidden;

	+ bp-validation-error {
		margin-left: $padding-x;
	}

	.clear-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -1px;
		right: 0;
		bottom: 0;

		.mat-icon-button {
			@include iconSize($round-control-height !important); // the height of the input

			&:hover .mat-button-focus-overlay {
				opacity: 0.1;
			}

			.mat-icon {
				font-size: 20px;
				width: auto;
				height: auto;
			}
		}
	}

	input {
		color: inherit !important;
	}
}

.rounded-select {
	@extend .control-appearance-round;

	&:not(.mat-select-empty) {
		background: $color-primary;
		border-color: $color-primary;

		.mat-select-trigger {
			.mat-select-arrow,
			.mat-select-value {
				color: white;
			}
		}
	}

	&.mat-select-disabled {
		border-color: $color-faded-dark-primary-text;

		.mat-select-trigger {
			.mat-select-arrow,
			.mat-select-value {
				&,
				.mat-select-placeholder {
					color: $color-faded-dark-primary-text;
				}
			}
		}
	}

	.mat-select-trigger {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: $round-control-height - $round-control-border-width * 2;

		.mat-select-value {
			max-width: none;
			line-height: 1;
		}

		.mat-select-placeholder,
		.mat-select-arrow,
		.mat-select-value {
			transition: color $animation-duration $animation-function;
		}

		.mat-select-placeholder,
		.mat-select-value {
			&::first-letter {
				text-transform: capitalize;
			}
		}

		.mat-select-placeholder {
			color: $color-slate-gray;
		}

		.mat-select-arrow {
			margin-right: 0;
		}
	}
}
