$font-family: 'Museo', sans-serif;

$color-mist             : #f9f9ff;
$color-blue-zodiac      : #0f213c;
$color-biscay           : #173055;
$color-bittersweet      : #ff6666;
$color-spring-green     : #33ff99;
$color-grey-mystic      : #d8dfea;
$color-mystic           : #e9edf3;
$color-slate-gray       : #7a8595;
$color-dark-primary-text: $color-blue-zodiac;
$color-border           : #bfc9d6;
$color-comet            : #585c6d;
$color-link-water       : #eff3fa;
$color-lynch            : #77869f;
$color-trout            : #4e5664;
$color-titan-white      : #f7f7ff;
$color-zircon     		: #f4f6ff;
$color-zircon-blue    	: #edf3ff;
$color-zumthor    	    : #e9f2ff;
$color-link-water       : #e4edf9;
$color-perfume          : #c7c7f9;
$color-manatee          : #8c95a4;
$color-hit-gray         : #A3ACB9;
$color-spun-pearl       : #ABAEBC;
$color-zuccini          : #073E23;
$color-hawkes-blue     	: #eef3fe;
$color-mid-gray			: #656568;
$color-black-pearl		: #07172e;
$color-pigeon-post		: #b5bedd;
$color-french-pass		: #bbd8ff;
$color-cadet-blue		: #abb4be;

$color-faded-dark-primary-text: fade-out($color-dark-primary-text, 0.35);

$root-container-max-width   : 1200px;
$root-container-padding-y   : 15px;
$root-container-padding-x-sm: 20px;
$root-container-padding-x-md: 30px;
$root-container-padding-x   : 30px;

$border-radius: 10px;

$breakpoint-phone     : 375px; //iphone 6/7/8
$breakpoint-phone-lg  : 500px;
$breakpoint-widescreen: $root-container-max-width + $root-container-padding-x * 2;

$menu-height-sm: 50px;
$menu-height-md-xl: 80px;
