.transparent {
	opacity: 0;
}

.animated-fade-in {
	opacity: 1;
	transition: opacity 0.25s ease;
}

.color-primary {
	color: $color-primary;
}
