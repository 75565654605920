.bp-modal-overlay-pane {
	mat-dialog-container {
		padding: 0;

		.btn-modal-icon-close {
			top: 0 !important;
			right: 0 !important;

			bp-svg-icon {
				width: 15px;
			}
		}
	}
}
