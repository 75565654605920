@use '@angular/material' as mat;

:root .longread-content-typography {
	@extend .rich-text;

	a:not(:has(img)) {
		@include promoLink();
		color: $color-primary !important;
	}

	blockquote {
		color: $color-primary;
		padding: 38px 0 34px;
		border-left: none;
		margin: 50px 0;

		@include mat.typography-level($phone-base-typography-config, subheading-1);

		@include media('>=tablet') {
			@include mat.typography-level($tablet-base-typography-config, subheading-1);
		}

		@include media('>=laptop') {
			margin: 80px 0;
			@include mat.typography-level($laptop-base-typography-config, subheading-1);
		}

		background:
			url('/assets/newsroom/news-page/quote.svg') left top no-repeat,
			linear-gradient(90deg, white 0%, $color-primary 25%, $color-primary 75%, white 100%) right top 8px no-repeat,
			linear-gradient(90deg, white 0%, $color-primary 25%, $color-primary 75%, white 100%) left bottom no-repeat;
		background-size:
			auto auto,
			50% 3px,
			50% 3px;

		// Sometimes there is <p> inside blockquote
		:last-child {
			margin-bottom: 0;
		}
	}

	ol,
	ul {
		margin: 2em 0;
		padding-left: 1em;

		@include media('>=tablet') {
			padding-left: 2.5em;
		}

		li {
			margin: 1.35em 0;
		}
	}

	ol {
		counter-reset: section;

		& > li {
			counter-increment: section;
			padding-left: 0.5em;

			&::marker {
				content: counters(section, '.') ' ';
			}
		}
	}

	aside {
		padding-left: 1em;
		border-left: 2px solid $color-primary;
	}
}
